//MARK: used in v2
import classNames from "classnames"
import React, { ComponentProps } from "react"

import Typography, { TypographySize } from "./Typography"
import { Color } from "../../../constants/V2/color"
import { formatPrice } from "../../../utils/price"
import { ProductPrice } from "../../../utils/shop"

import { usePromotion } from "@hooks/products"

type Props = {
  productPrices: ProductPrice
  promotionStartDate?: string
  promotionEndDate?: string
  priceFontWeight?: ComponentProps<typeof Typography>["weight"]
  originalPriceLabel?: string
  className?: string
  priceFontSize?: TypographySize
  superscriptFontsize?: TypographySize
  subscriptText?: string
  priceColor?: Color
  showDecimals?: boolean
  overrideShowDiscount?: boolean
}

const PriceDisplay = ({
  promotionStartDate,
  promotionEndDate,
  productPrices,
  className,
  originalPriceLabel,
  priceFontWeight = "book",
  priceFontSize = "body-md",
  superscriptFontsize = "subscript-sm",
  subscriptText,
  priceColor = Color.Charcoal,
  showDecimals = true,
  overrideShowDiscount = false,
}: Props) => {
  const displayPrice = productPrices
    ? formatPrice(productPrices.price, {
        shouldShowDecimals: showDecimals,
        hasThousandsSeparator: true,
      })
    : ""
  const displayOriginalPrice = productPrices.originalPrice
    ? formatPrice(productPrices.originalPrice, {
        shouldShowDecimals: showDecimals,
        hasThousandsSeparator: true,
      })
    : ""

  const { renderPromotion } = usePromotion(promotionStartDate, promotionEndDate)

  const renderOriginalPrice =
    (renderPromotion || overrideShowDiscount) &&
    productPrices?.originalPrice &&
    parseFloat(productPrices.originalPrice) > 0

  return (
    <div
      className={classNames(
        "flex flex-col items-start lg:items-start",
        className
      )}
    >
      {renderOriginalPrice && (
        <div className="flex flex-row uppercase">
          {originalPriceLabel && (
            <Typography
              color={priceColor}
              text={originalPriceLabel}
              font="grotesk"
              size={superscriptFontsize}
              weight="book"
              className="pr-4 opacity-40"
            />
          )}
          <Typography
            color={priceColor}
            text={displayOriginalPrice}
            font="grotesk"
            size={superscriptFontsize}
            weight="book"
            className="line-through opacity-40"
          />
        </div>
      )}
      <Typography
        color={priceColor}
        text={displayPrice}
        font="grotesk"
        size={priceFontSize}
        weight={priceFontWeight}
      />
      {subscriptText && (
        <Typography
          color={priceColor}
          weight="book"
          font="grotesk"
          size={superscriptFontsize}
          text={subscriptText}
          className={classNames("-mt-8 text-left lg:mt-0")}
        />
      )}
    </div>
  )
}

export default PriceDisplay
