import React from "react"

type Props = {
  className?: string
}

const MinusIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path d="M6 11L6 9L14 9V11L6 11Z" fill="#D8D8D8" />
    </svg>
  )
}

export default MinusIcon
