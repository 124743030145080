import classNames from "classnames"
import React, { useCallback } from "react"

import { Color } from "../../../../constants/V2/color"
import DeleteIcon from "../Icons/DeleteIcon"
import MinusIcon from "../Icons/MinusIcon"
import PlusIcon from "../Icons/PlusIcon"
import Typography from "../Typography"

interface Props {
  value: number
  maxValue?: number
  minValue?: number
  className?: string
  disabled?: boolean
  isDeleteEnabled?: boolean
  onQuantityUpdate?: (quantity: number) => void
  onDelete?: () => void
}

const QuantityInput = ({
  value,
  maxValue = 3,
  minValue = 1,
  className,
  disabled,
  isDeleteEnabled,
  onQuantityUpdate,
  onDelete,
}: Props) => {
  const buttonStyles =
    "rounded-[5px] h-full cursor-pointer w-full flex items-center"
  const decreaseQuantity = useCallback(() => {
    if (value === minValue && isDeleteEnabled && onDelete) {
      onDelete()
    } else if (onQuantityUpdate) onQuantityUpdate(Math.max(value - 1, minValue))
  }, [value, isDeleteEnabled, onDelete, onQuantityUpdate, minValue])

  const increaseQuantity = useCallback(() => {
    if (onQuantityUpdate) onQuantityUpdate(Math.min(value + 1, maxValue))
  }, [onQuantityUpdate, value, maxValue])

  return (
    <div
      className={classNames(
        "relative flex h-[36px] w-[84px] items-center gap-4 rounded-8 border border-charcoal/20",
        className
      )}
    >
      <button
        type="button"
        aria-label="Decrease quantity"
        onClick={decreaseQuantity}
        disabled={disabled || (value === minValue && !onDelete)}
        data-test-id="decrease-quanitity-button"
        className={classNames(buttonStyles, "group", {
          "pointer-events-none border-black/20 bg-transparent":
            disabled || (value === minValue && !onDelete),
        })}
      >
        {isDeleteEnabled && value === minValue ? (
          <DeleteIcon
            className={classNames(
              "ml-[11px] stroke-black group-hover:stroke-orange",
              { "stroke-charcoal/20": disabled }
            )}
          />
        ) : (
          <MinusIcon
            className={classNames(
              "ml-[13px] h-[2px] w-8 bg-charcoal group-hover:bg-blue",
              { "bg-charcoal/20": disabled || value === minValue }
            )}
          />
        )}
      </button>
      <div
        className={classNames(
          "mt-[3px] select-none content-center text-center"
        )}
      >
        <Typography
          text={value.toString()}
          font="grotesk"
          color={Color.Black}
          size="body-md-fixed"
          weight="medium"
          data-test-id="quanitity-input-box"
          className="w-16"
        />
      </div>

      <button
        type="button"
        aria-label="Increase quantity"
        onClick={increaseQuantity}
        disabled={disabled || value === maxValue}
        data-test-id="increase-quanitity-button"
        className={classNames(buttonStyles, "group", {
          "pointer-events-none border-black/20 bg-transparent":
            disabled || value === maxValue,
        })}
      >
        <PlusIcon
          className={classNames(
            "ml-[3px] h-10 fill-charcoal group-hover:fill-blue",
            { "fill-charcoal/20": disabled || value === maxValue }
          )}
          strokeWidth={"3"}
        />
      </button>
    </div>
  )
}

export default QuantityInput
