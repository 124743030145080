import classNames from "classnames"
import React from "react"

import Typography from "./Typography"
import { Color } from "../../../constants/V2/color"

import { getTextColorClass } from "@utils/V2/color"

type CheckIconProps = {
  overrideColor?: Color
}

function CheckIcon({ overrideColor = Color.Charcoal }: CheckIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      className={classNames("self-start", getTextColorClass(overrideColor))}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        stroke="currentColor"
        d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 8-8 8 8 0 0 1 8 8Z"
        opacity={0.3}
      />
      <path stroke="currentColor" d="m9.082 12 2.245 2.245 4.082-4.082" />
    </svg>
  )
}

type Props = {
  text: string
  size?: "body-lg" | "body-md"
  answerText?: string
  iconOverrideColor?: Color
  textColor?: Color
}

const BulletItem = ({
  text,
  size = "body-lg",
  answerText,
  iconOverrideColor,
  textColor = Color.Charcoal,
}: Props) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-[min-content_auto_auto] items-center gap-x-8",
        getTextColorClass(textColor)
      )}
    >
      <CheckIcon overrideColor={iconOverrideColor} />
      <div className="flex flex-col">
        <Typography
          color={textColor}
          text={text}
          font="grotesk"
          size={size}
          weight="book"
          className="mt-px"
        />

        {answerText ? (
          <Typography
            color={textColor}
            text={answerText}
            font="grotesk"
            size={size}
            weight="book"
            className="mt-px opacity-70"
          />
        ) : null}
      </div>
    </div>
  )
}

export default BulletItem
